import React from 'react';

import cn from 'classnames';
import Link from 'next/link';

import styles from './index.module.less';

interface TitleProps {
  subTitle: string;
  title: string;
  link: string;
  jumpText: string;
}

export const Title: React.FC<TitleProps> = (props) => {
  const { subTitle, title, link, jumpText } = props;
  return (
    <>
      <h2 className={cn(styles.title, 'DINPro-Medium')}>{title}</h2>
      <div className={cn(styles.subTitle, 'DINPro')}>
        {subTitle}
        <Link href={link}>
          <a className={styles.loginLink}>{jumpText}</a>
        </Link>
      </div>
    </>
  );
};
